@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PPNeueMontreal";
  src: url("./fonts/PPNeueMontreal-SemiBold.woff") format("woff"),
    url("./fonts/PPNeueMontreal-SemiBold.woff2") format("woff2"),
    url("./fonts/PPNeueMontreal-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Exposure";
  src: url("./fonts/Exposure.woff") format("woff"),
    url("./fonts/Exposure.woff2") format("woff2"),
    url("./fonts/Exposure.otf") format("otf");
}
@font-face {
  font-family: "ExposureItalic";
  src: url("./fonts/Exposure-Italic.woff") format("woff"),
    url("./fonts/Exposure-Italic.woff2") format("woff2"),
    url("./fonts/Exposure-Italic.otf") format("otf");
}

@layer base {
  body {
    background-color: #fffcef;
  }
  * {
    font-family: "PPNeueMontreal", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
}

@layer components {
  .btn-reverse {
    @apply outline-none cursor-pointer font-bold py-2 px-4 border-2  uppercase   btn-animated hover:shadow-none transition-all ease-in-out duration-300;
  }
  .btn-animated {
    @apply border-black  shadow-uiBoxShadowMisc disabled:shadow-none;
  }
  .btn {
    @apply outline-none cursor-pointer font-bold py-2 px-4 border-transparent    uppercase  enabled:hover:btn-animated transition-all ease-in-out duration-300;
  }
  .btn-purple-outlined {
    @apply text-uptop-purple rounded-full  border-2 border-uptop-purple  disabled:bg-surface-400 disabled:border-none disabled:text-surface-600;
  }
  .btn-purple {
    @apply text-white rounded-full bg-uptop-purple active:bg-white active:border-2 active:border-uptop-purple active:text-uptop-purple disabled:bg-uptop-purple/70 disabled:text-white;
  }
  .btn-tan {
    @apply text-black hover:text-surface-700 bg-uptop-tan active:bg-uptop-tan/70  border-2 border-black  disabled:bg-surface-300  disabled:text-surface-500 disabled:cursor-not-allowed;
  }
  .btn-white {
    @apply text-black  hover:text-surface-700 border-2 border-black bg-white  active:bg-surface-200 disabled:bg-surface-300 disabled:text-surface-500 disabled:border-none disabled:cursor-default;
  }
  .btn-red {
    @apply text-white  bg-uptop-redTint  active:bg-white active:border-2 active:border-uptop-redTint active:text-uptop-redTint disabled:bg-uptop-redTint/70 disabled:text-white;
  }
}
.text-stroke {
  text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 2px 2px 0 #000;
}
.glowing-star::before {
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073,
    0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
}
.shiny-button {
  background-color: rgb(221, 54, 2);
  position: relative;
  overflow: hidden;
}

.shiny-button::before {
  content: "";
  position: absolute;
  top: -100%;
  left: -200%;
  width: 200%;
  height: 200%;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 60%
  );
  animation: shimmer 2s infinite;
}

.shimmer {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 25%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.1) 75%
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.blob {
  position: relative;
  border-radius: 50%;
  margin: 10px;
  height: 10px;
  width: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.blob.amber {
  background: rgba(255, 191, 0, 1);
  box-shadow: 0 0 0 0 rgba(255, 191, 0, 1);
  animation: pulse-amber 2s infinite;
}

@keyframes pulse-amber {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 191, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 191, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 191, 0, 0);
  }
}

#finicityConnectIframe {
  z-index: 1000;
  position: fixed;
}
